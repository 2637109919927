<template>
  <div v-if="StoreObj">
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <!-- <CreateTicketWQStage
      v-if="StoreObj.workqueue_data"
      @clicked="dialogCreateWorkQueueStageEmit"
      :dialogCreateWorkQueueStage="dialogCreateWorkQueueStage"
      :StoreWQList="StoreObj.workqueue_data.workqueue_stages"
      :stageIndex="stageIndex"
      :StoreObj="stageObj"
      :ticket_id="StoreObj.ticket_id" /> -->
    <!-- <RearrangeTicketWQStagesDialog
      :rearrangeTicketStagesDaialog="rearrangeTicketStagesDaialog"
      @clicked="rearrangeTicketStagesDialogEmit"
      :StagesList="StoreObj.workqueue_data.workqueue_stages"
      :firstPart="firstPart"
      :secondPart="secondPart"
      :StoreObj="StoreObj"
      v-if="StoreObj.workqueue_data" /> -->
    <ReplyToEmailThreadDialog :dialogReplytToEmail="dialogReplytToEmail" :StoreObj="StoreObj" @clicked="dialogReplytToEmailEmit" />
    <v-card
      elevation="1"
      outlined
      color="#f0f0f0"
      class="pa-0 overflow-y-auto overflow-x-hidden rounded-lg custom-scrollbar"
      :height="OuterCardHeight">
      <v-card-text class="pa-2" style="position: sticky; top: 0; z-index: 1; background-color: white">
        <div class="d-flex justify-space-between">
          <div class="black--text d-flex justify-space-between">
            <v-img v-if="StoreObj.ticket_origin === 'EMAIL'" color="black" src="../../assets/email.png" height="25px" width="25px" />
            <v-img
              v-if="StoreObj.ticket_origin === 'INBOUNDCALL' || StoreObj.ticket_origin === 'OUTBOUNDCALL'"
              color="black"
              src="../../assets/callIcon.png"
              height="25px"
              width="25px" />
            <v-img v-if="StoreObj.ticket_origin === 'MANUAL'" color="black" src="../../assets/manualTicket.png" height="25px" width="25px" />

            <h3 class="pl-2 d-flex justify-space-between" v-if="StoreObj.ticket_description">
              {{ StoreObj.ticket_description.subject ? StoreObj.ticket_description.subject : "No subject" }}
            </h3>

            <v-text-field
              label="Change ticket Subject"
              dense
              color="secondary"
              v-model="ticketSubject"
              v-if="editTicketSubjet"
              class="field_height field_label_size FontSize ml-3"
              outlined>
            </v-text-field>
          </div>
          <div>
            <v-btn
              x-small
              text
              color="primary"
              class="text-capitalize"
              v-if="StoreObj.status !== 'CLOSED' && StoreObj.ticket_origin !== 'EMAIL' && editTicketSubjet"
              small
              @click="addSubjectToTicket">
              save
            </v-btn>
            <!-- <v-btn icon @click="editTicketSubjet = !editTicketSubjet" v-if="StoreObj.status !== 'CLOSED' && StoreObj.ticket_origin !== 'EMAIL'">
              <v-icon color="primary" small> mdi-pencil </v-icon>
            </v-btn> -->
            <v-btn
              v-if="
                isExternalStage &&
                StoreObj.status !== 'CLOSED' &&
                (StoreObj.ticket_origin === 'EMAIL' || StoreObj.ticket_origin === 'MANUAL') &&
                StoreObj.ticket_description.email &&
                StoreObj.ticket_description.email.length > 0
              "
              x-small
              class="text-capitalize"
              dark
              depressed
              color="#0073ff"
              @click="dialogReplytToEmail = true">
              <v-icon left small class="">mdi-reply-all-outline</v-icon> Reply
            </v-btn>
          </div>
        </div>
        <div class="py-1 d-flex justify-start">
          <v-chip small> #{{ StoreObj.ticket_display_id }} </v-chip>
          <v-tooltip bottom color="secondary">
            <template v-slot:activator="{ on, attrs }">
              <div class="ml-3" v-bind="attrs" v-on="on">
                <v-icon color=" ml-2" small class="">mdi-clock-time-two-outline</v-icon>
                {{ new Date(StoreObj.raised_on).toLocaleString() }}
              </div>
            </template>
            <span>Ticket Created on {{ new Date(StoreObj.raised_on).toLocaleString() }} </span>
          </v-tooltip>
        </div>
        <v-tabs small color="secondary" fixed-tabs v-model="tab">
          <v-tab>Conversations</v-tab>
          <v-tab>Attachments</v-tab>
          <!-- <v-tab>Timeline </v-tab> -->
        </v-tabs>
      </v-card-text>
      <v-card-text class="pa-0">
        <v-tabs-items v-model="tab">
          <!-- CONVERSATIONS -->
          <v-tab-item>
            <v-row no-gutters>
              <v-col
                align="center"
                justify="center"
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="12"
                v-if="this.StoreObj.ticket_origin !== 'EMAIL' && this.StoreObj.ticket_origin != 'MANUAL'">
                <v-card :height="OuterCardHeight - 58">
                  <h2 class="grey--text pt-16"><v-icon left color="grey">mdi-chat-remove-outline</v-icon>No conversations found.</h2>
                </v-card>
              </v-col>

              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="12"
                v-if="this.StoreObj.ticket_origin == 'EMAIL' || this.StoreObj.ticket_origin == 'MANUAL'">
                <h3 class="pl-2">
                  Email Subject:
                  {{ StoreObj.ticket_origin === "EMAIL" ? StoreObj.ticket_description["email"][0]["subject"] : StoreObj.ticket_description.subject }}
                </h3>
                <!-- <div v-for="(splittedEmial, id) in StoreObj.ticket_description.latestEmailContent.split('----SPLIT_HERE----')" :key="id">
                  <h3 class="pl-2" v-if="id === 0">
                    Email Subject:
                    {{
                      StoreObj.ticket_origin === "EMAIL" ? StoreObj.ticket_description["email"][0]["subject"] : StoreObj.ticket_description.subject
                    }}
                  </h3> -->
                <div v-for="(thread, id) in StoreObj.ticket_description.email" :key="id">
                  <!-- <v-card height="" class="overflow-auto elevation-2 mt-2 pa-2" v-html="removeTagsAndEntities(splittedEmial)"></v-card> -->
                  <v-card class="overflow-auto elevation-2 mt-2 pa-2">
                    <div class="d-flex justify-space-between">
                      <div>
                        <strong>From: </strong>
                        <span
                          ><u>{{ thread.from }}</u>
                        </span>
                      </div>

                      <div class="d-flex justify-space-between">
                        <span style="color: #666">{{ new Date(thread.date).toLocaleString("en-IN", { hour12: true }) }}</span>
                        <!-- Reply button -->
                        <!-- <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mt-n2" v-bind="attrs" v-on="on" icon> <v-icon color="black">mdi-dots-vertical</v-icon> </v-btn>
                          </template>
                          <v-list dense>
                            <v-list-item v-for="(item, index) in replyItems" :key="index">
                              <v-list-item-title
                                ><v-btn
                                  @click="sendOrReplayEmail(thread, item.title == 'Forward' ? item.title : undefined)"
                                  :disabled="item.title == 'Reply All' && isContainsMultipleEmailIds(thread)"
                                  small
                                  text
                                  depressed>
                                  <v-icon left small class="text-capitalize">{{ item.icon }}</v-icon> {{ item.title }}
                                </v-btn></v-list-item-title
                              >
                            </v-list-item>
                          </v-list>
                        </v-menu> -->
                      </div>
                    </div>

                    <div>
                      <strong>To: </strong>
                      <span v-if="thread.toHtml.value.length > 0" v-for="(toAddress, idx) in thread.toHtml.value" :key="idx"
                        ><u>{{ toAddress.address.trim() }} </u>{{ idx !== thread.toHtml.value.length - 1 ? ", " : "" }}
                      </span>
                      <span v-if="thread.toHtml.value.length == 0" v-for="(toAddress, idx) in thread.to" :key="idx"
                        ><u>{{ toAddress }} </u>
                      </span>
                    </div>

                    <div v-if="thread.ccHtml.value && thread.ccHtml.value.length > 0">
                      <strong>CC: </strong>
                      <span v-for="(ccAddress, idx) in thread.ccHtml.value" :key="idx"
                        ><u>{{ ccAddress.address }}</u
                        >{{ idx !== thread.ccHtml.value.length - 1 ? ", " : "" }}
                      </span>
                    </div>
                    <v-card-text class="px-0">
                      <div v-html="removeTagsAndEntities(thread.textMessage)"></div>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </v-tab-item>
          <!-- ATTACHMENTS -->
          <v-tab-item v-if="StoreObj.ticket_description">
            <v-row no-gutters>
              <v-col align="center" justify="center" cols="12" xs="12" sm="12" md="12" lg="12" v-if="this.StoreObj.ticket_origin !== 'EMAIL'">
                <v-card :height="OuterCardHeight - 58">
                  <h2 class="grey--text pt-16"><v-icon left color="grey">mdi-paperclip-off</v-icon>No Attachments found.</h2>
                </v-card>
              </v-col>
              <v-col v-if="StoreObj.ticket_origin == 'EMAIL'">
                <EmailAttachments :emailAttachments="StoreObj.ticket_description.email" />
              </v-col>
            </v-row>
          </v-tab-item>
          <!-- TIMELINE -->
          <!-- <v-tab-item>
            <v-card-text class="pa-0" v-if="StoreObj.workqueue_data">
              <v-row class="pa-1">
                <v-col cols="12" align="right" v-if="StoreObj.status != 'ARCHIVED'">
                  <v-card-actions class="py-0">
                    <v-spacer> </v-spacer>
                    <v-tooltip v-if="StoreObj.status !== 'CLOSED'" bottom color="secondary">
                      <template v-slot:activator="{ on, attrs }">
                        <div class="">
                          <v-icon @click="rearrangeTicketStagesDaialog = true" v-bind="attrs" v-on="on" color="primary" left small class=""
                            >mdi-arrange-send-to-back</v-icon
                          >
                        </div>
                      </template>
                      <span>Rearrange Stage</span>
                    </v-tooltip>
                    <v-tooltip v-if="StoreObj.status !== 'CLOSED'" bottom color="secondary">
                      <template v-slot:activator="{ on, attrs }">
                        <div class="">
                          <v-icon v-bind="attrs" v-on="on" color="primary" left small class="" @click="CreateOrEditStage((action = 'CREATE'))"
                            >mdi-plus</v-icon
                          >
                        </div>
                      </template>
                      <span>Add Stage</span>
                    </v-tooltip>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text v-if="StoreObj.workqueue_data">
              <v-timeline align-top dense class="timelineitem overflow-hidden">
                <v-timeline-item
                  :color="StoreObj.status == 'CLOSED' ? 'green' : getColor(index)"
                  small
                  :icon="StoreObj.status == 'CLOSED' ? 'mdi-check' : ''"
                  v-for="(stage, index) in StoreObj.workqueue_data.workqueue_stages"
                  :key="index">
                  <v-card dark color="secondary">
                    <v-row class="pa-1" no-gutters>
                      <v-col cols="12" align="right" v-if="StoreObj.status != 'ARCHIVED'">
                        <v-tooltip v-if="StoreObj.status !== 'CLOSED'" color="primary">
                          <template v-slot:activator="{ on, attrs }">
                            <div class="">
                              <v-icon v-bind="attrs" v-on="on" color="white" left small class="mt-n1" @click="CreateOrEditStage('EDIT', stage, index)"
                                >mdi-pencil</v-icon
                              >
                            </div>
                          </template>
                          <span>Edit Stage</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12">
                        <div class="">
                          <v-icon color="#F2F7A1" small left>mdi-badge-account</v-icon><strong>Stage:</strong>
                          {{ stage.stage_name.replace(/_/g, " ") }}
                        </div></v-col
                      >

                      <v-col cols="12">
                        <v-tooltip bottom color="primary">
                          <template v-slot:activator="{ on, attrs }">
                            <div class="">
                              <v-icon color="#F2F7A1" small left>mdi-check-decagram</v-icon>
                              <strong>Assinged To: </strong>
                              <v-icon v-bind="attrs" v-on="on" color="primary" left small class="mt-n1">{{
                                stage.stage_assign_to == "USER" ? "mdi-account-circle" : "mdi-home-city-outline"
                              }}</v-icon>
                            </div>
                          </template>
                          <span>{{ stage.stage_assign_to }} </span>
                        </v-tooltip>
                        <div class="">
                          <v-icon color="#F2F7A1" small left>mdi-badge-account</v-icon><strong>Name:</strong> {{ stage.stage_assigned_to_name }}
                        </div>
                        <div class="" v-if="stage.stage_service_life_limit">
                          <v-icon color="red" small left>mdi-timer-outline</v-icon><strong>Process Time:</strong>
                          {{ millisecondsToDuration(stage.stage_service_life_limit) }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
            <v-card v-if="!StoreObj.workqueue_data" :height="OuterCardHeight - 58" class="text-center">
              <h2 class="grey--text pt-16"><v-icon left color="grey"></v-icon>This ticket is not added to any work flow</h2>
            </v-card>
          </v-tab-item> -->
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
// import CreateTicketWQStage from "../Dialogs/CreateTicketWQStage.vue";
// // import RearrangeTicketWQStagesDialog from "@/components/Tickets/Dialogs/RarrangeTicketWQStagesDialog.vue"
import EmailAttachments from "./EmailAttachments.vue";
import ReplyToEmailThreadDialog from "@/components/Tickets/Dialogs/ReplyToEmailThreadDialog.vue";
// import { AddSubjectToTicket } from "@/graphql/mutations";
// import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    StoreObj: Object,
    isExternalStage: Boolean,
  },
  components: {
    Snackbar,
    EmailAttachments,
    ReplyToEmailThreadDialog,
  },
  data: () => ({
    ticketSubject: "",
    tab: null,
    panels: [0, 1],
    stageIndex: 0,
    OuterCardHeight: 0,
    editTicketSubjet: false,
    applyBackground: true,
    dialogReplytToEmail: false,
    dialogCreateWorkQueueStage: false,
    rearrangeTicketStagesDaialog: false,
    stageObj: {},
    SnackBarComponent: {},
    items: ["Conversations", "Timeline", "Attachments", "History", "Call Logs"],
    firstPart: [],
    secondPart: [],
  }),
  watch: {
    StoreObj(value) {
      if (value && value.ticket_origin !== "EMAIL") this.ticketSubject = value.ticket_description.subject;
    },
  },
  computed: {
    getCurrentStageIndex() {
      if (this.StoreObj.workqueue_data) {
        let index = 0;
        for (let i = 0; i < this.StoreObj.workqueue_data.workqueue_stages.length; i++) {
          if (this.StoreObj.workqueue_data.workqueue_stages[i].stage_name == this.StoreObj.ticket_stage_name) {
            index = i;
            if (index == 0) {
              this.firstPart = [this.StoreObj.workqueue_data.workqueue_stages[0]];
            } else {
              this.firstPart = this.StoreObj.workqueue_data.workqueue_stages.slice(0, index);
            }
            if (this.StoreObj.workqueue_data.workqueue_stages.length > 2) {
              this.secondPart = this.StoreObj.workqueue_data.workqueue_stages.slice(index + 1);
            }
          }
        }

        return index;
      }
    },
  },
  mounted() {
    if (this.StoreObj && this.StoreObj.ticket_origin !== "EMAIL" && this.StoreObj.ticket_description)
      this.ticketSubject = this.StoreObj.ticket_description.subject;

    this.OuterCardHeight = window.innerHeight - 120;
  },
  methods: {
    removeTagsAndEntities(input) {
      let cleanedString = input.replace(/&lt;/g, "");
      cleanedString = cleanedString.replace(/&gt;/g, "");

      return cleanedString;
    },
    isFirstTimelineItem(index) {
      return index === 0;
    },
    millisecondsToDuration(milliseconds) {
      const totalHours = milliseconds / (60 * 60 * 1000);
      return `${totalHours.toFixed(0)} Hours`;
    },
    getColor(index) {
      if (this.getCurrentStageIndex && this.getCurrentStageIndex === index) {
        return "primary";
      } else if (index < this.getCurrentStageIndex) {
        return "green";
      } else {
        return "grey";
      }
    },

    CreateOrEditStage(action, item, index) {
      if (action == "EDIT") {
        this.stageObj = item;
        this.stageIndex = index;
      }
      this.stageObj.action = action;
      this.dialogCreateWorkQueueStage = true;
    },
    dialogCreateWorkQueueStageEmit(Toggle) {
      this.dialogCreateWorkQueueStage = false;
      if (Toggle == 2) {
        this.TimelineCardEmit(2);
      }
    },
    rearrangeTicketStagesDialogEmit(Toggle) {
      this.rearrangeTicketStagesDaialog = false;
      if (Toggle == 2) {
        this.TimelineCardEmit(2);
      }
    },
    TimelineCardEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
    async dialogReplytToEmailEmit(toggle) {
      this.dialogReplytToEmail = false;
      if (toggle === 2) this.TimelineCardEmit(toggle);
      // if (toggle === 2) this.StoreObj = await this.getTicketDetails(this.StoreObj.ticket_id);
    },
    // async addSubjectToTicket() {
    //   try {
    //     const result = await API.graphql(
    //       graphqlOperation(AddSubjectToTicket, {
    //         input: {
    //           ticket_id: this.StoreObj.ticket_id,
    //           ticket_subject: this.ticketSubject,
    //         },
    //       })
    //     );

    //     if (JSON.parse(result.data.AddSubjectToTicket).status == "Success") {
    //       this.SnackBarComponent = {
    //         SnackbarVmodel: true,
    //         SnackbarColor: "green",
    //         Top: true,
    //         SnackbarText: JSON.parse(result.data.AddSubjectToTicket).message,
    //       };
    //     }

    //     this.TimelineCardEmit(2);
    //     this.editTicketSubjet = false;
    //   } catch (error) {
    //     console.log(error);
    //     this.SnackBarComponent = {
    //       SnackbarVmodel: true,
    //       SnackbarColor: "red",
    //       Top: true,
    //       SnackbarText: error.errors[0].message,
    //     };
    //   }
    // },
  },
};
</script>
<style scoped>
.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse)::before {
  left: calc(46px - 1px);
  right: initial;
  width: 5px;
}
.timelineitem .theme--light.v-timeline::before {
  background: rgba(0, 0, 0, 0.12);
}
.theme--light.v-timeline::before {
  background: rgb(160, 160, 246);
  background-color: rgb(160, 160, 246);

  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-image: none;
  background-size: auto 10px;
  background-origin: padding-box;
  background-clip: border-box;
}
</style>
