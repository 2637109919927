<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogReplytToEmail" persistent max-width="900px">
      <v-card class="overflow-y-auto">
        <v-toolbar dark dense class="elevation-0" color="primary">
          <!-- <div>{{ action == "CREATE" ? "Complete Tag" : "Edit Tag" }}</div> -->
          <v-toolbar-title> Reply All</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogReplytToEmailEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="text-center mt-3">
          <v-form ref="form">
            <v-row no-gutters>
              <v-col cols="12">
                <v-combobox
                  dense
                  outlined
                  v-model="toAddresses"
                  :items="[]"
                  item-text="user_email_id"
                  item-value="user_email_id"
                  label="To"
                  color="secondary"
                  multiple
                  chips>
                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <v-chip
                      small
                      :color="/^[a-z0-9.]+@[a-z0-9]+(?:\.[a-z0-9]+)+$/.test(item) ? '' : 'red'"
                      :key="JSON.stringify(item)"
                      v-bind="attrs"
                      :input-value="selected"
                      class="my-1">
                      <v-avatar class="accent white--text pa-1" left>{{ item.slice(0, 1).toUpperCase() }}</v-avatar>
                      {{ item }} <v-icon small right @click="parent.selectItem(item)"> $delete </v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
              <v-col cols="12">
                <v-combobox
                  outlined
                  v-model="ccAddresses"
                  :items="[]"
                  item-text="user_email_id"
                  item-value="user_email_id"
                  label="Cc"
                  color="secondary"
                  multiple
                  dense
                  chips>
                  <template v-slot:selection="data">
                    <v-chip
                      :key="JSON.stringify(data.item)"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      @click:close="data.parent.selectItem(data.item)">
                      <v-avatar class="accent white--text" left v-text="data.item.slice(0, 1).toUpperCase()"></v-avatar>
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
              <!-- <v-col cols="12">
                  <v-combobox
                    dense
                    color="secondary"
                    im="bcc"
                    :items="GetAllUsersList.map((user) => user.user_email_id)"
                    item-text="user_email_id"
                    item-value="user_email_id"
                    label="Bcc"
                    multiple
                    chips>
                    <template v-slot:selection="data">
                      <v-chip
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)">
                        <v-avatar class="accent white--text" left v-text="data.item.slice(0, 1).toUpperCase()"></v-avatar>
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col> -->

              <!-- <v-col cols="6" class="">
                <v-file-input
                  dense
                  outlined
                  :show-size="1000"
                  multiple
                  counter
                  v-model="emailAttchments"
                  color="secondary"
                  prepend-inner-icon="mdi-paperclip"
                  prepend-icon=""
                  label="Attach Files">
                  <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" color="deep-purple accent-4" dark label small>
                      {{ text }}
                    </v-chip>

                    <span v-else-if="index === 2" class="text-overline grey--text text--darken-3 mx-2">
                      +{{ emailAttchments.length - 2 }} File(s)
                    </span>
                  </template>
                </v-file-input></v-col
              > -->

              <v-col cols="6" :md="emailAttchments.length > 1 ? '12' : '6'" class="">
                <v-file-input
                  dense
                  outlined
                  :show-size="1000"
                  multiple
                  counter
                  v-model="emailAttchments"
                  color="secondary"
                  prepend-inner-icon="mdi-paperclip"
                  prepend-icon=""
                  label="Attach Files"
                  :rules="[checkFileSize || 'Total file size must be lesser than 7MB']">
                  <template v-slot:selection="{ index, text }">
                    <v-chip color="deep-purple accent-4" dark label small>
                      {{ text }} <v-icon color="red" right small @click.stop="emailAttchments.splice(index, 1)">mdi-close</v-icon>
                    </v-chip>
                  </template>
                </v-file-input></v-col
              >
              <v-col cols="12" align="center" justify="center">
                <h3 class="" v-if="isTemplateSeleted">Preview</h3>
                <v-card elevation="0" height="350px" class="overflow-auto py-0">
                  <vue-editor :editor-toolbar="customToolbar" v-model="selectedTemplate.email_template_content" ref="editor" />
                </v-card>

                <!-- <v-btn small dark color="green" class="text-capitalize mt-3" v-if="isTemplateSeleted" @click="copyTemplateContent"
                  >Use template</v-btn
                > -->
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius text-capitalize" color="primary" outlined @click="dialogReplytToEmailEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >
          <v-btn
            :loading="loading"
            small
            class="borderRadius text-capitalize mr-2"
            color="primary"
            :disabled="!validateToAddress || !validteCCAddress || selectedTemplate.email_template_content === '' || !checkFileSize"
            @click="replyAllMethod"
            ><v-icon small class="mr-1">mdi-check</v-icon>Reply</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import Snackbar from "@/components/Extras/Snackbar.vue";

import { VueEditor } from "vue2-editor";

export default {
  props: {
    dialogReplytToEmail: Boolean,
    StoreObj: Object,
  },
  components: { Snackbar, VueEditor },
  mixins: [],
  watch: {
    async dialogReplytToEmail(value) {
      if (value) {
        this.innerheight = window.innerHeight - 200;
        this.toAddresses.push(this.StoreObj.ticket_raised_by);
      }
      // this.$refs.editor.quill.focus();
    },
    "selectedTemplate.email_template_content"(val) {
      if (val.length > 0) {
        this.copyTemplateContent();
      }
    },
  },
  data: () => ({
    Toggle: 0,
    loading: false,
    isTemplateSeleted: false,
    innerheight: 0,
    message: "",
    user_email_id: "",
    selectedTemplate: { email_template_content: "" },
    selectedTemplateContent: "",
    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      [{ color: [] }, { background: [] }],
      ["bold", "italic", "underline"],
      [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
    customToolbar2: [],
    toAddresses: [],
    ccAddresses: [],
    bcc: [],
    emailAttchments: [],
    SnackBarComponent: {},
  }),
  computed: {
    checkFileSize() {
      if (this.emailAttchments.length > 0) {
        let totalFilesSize = 0;
        this.emailAttchments.forEach((file) => {
          totalFilesSize += file.size;
        });
        if (totalFilesSize > 7000000) return false;
      }
      return true;
    },
    validateToAddress() {
      if (this.toAddresses.length === 0) return false;

      return this.toAddresses.every((email) => {
        return /^[a-z0-9.]+@[a-z0-9]+(?:\.[a-z0-9]+)+$/.test(email);
      });
    },
    validteCCAddress() {
      if (this.ccAddresses.length > 0) {
        return this.ccAddresses.every((email) => {
          return /^[a-z0-9.]+@[a-z0-9]+(?:\.[a-z0-9]+)+$/.test(email);
        });
      }
      return true;
    },
  },
  methods: {
    copyTemplateContent() {
      this.message = this.selectedTemplate.email_template_content;
    },
    async getPresignedUrl(key) {
      try {
        // const axios = require("axios");

        const params = {
          key: key,
          command: "WEB_FORM_S3_URL",
        };

        const config = {
          method: "post",
          url: "https://kfofet10y2.execute-api.ap-northeast-2.amazonaws.com/DEV/getPreSignedUrl",
          headers: {
            "Content-Type": "application/json",
          },
          data: params,
        };

        const result = await axios.request(config);

        if (result.data.status == "Success") return result.data.message;
      } catch (error) {
        console.log("error in closing ticket", error);
      }
    },
    async replyAllMethod() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          let attachmentsArray = [];
          if (this.emailAttchments.length > 0) {
            for (let i = 0; i < this.emailAttchments.length; i++) {
              let file = this.emailAttchments[i];
              const file_extension = file.name.split(".")[1];
              const key = `OUTBOUND/${file.name.split(".")[0]}.${file_extension}`;
              const presignedUrl = await this.getPresignedUrl(key);
              const response = await axios.put(presignedUrl, file, {
                headers: {
                  "Content-Type": file.type,
                },
              });

              if (response.status == 200 && response.statusText == "OK") {
                let file_Url = `https://hellowrk-ticket-attachments.s3.ap-northeast-2.amazonaws.com/${key}`;
                let fileDeatils = {
                  filename: file.name,
                  path: file_Url,
                };
                attachmentsArray.push(fileDeatils);
              }
            }
          }
          const inputParams = {
            ticket_id: this.StoreObj.ticket_id,
            email_text: JSON.stringify(this.message),
            organisation_customer_email_id: this.StoreObj.customer_care_email_id,
            toAddresses: this.toAddresses,
            ccAddresses: this.ccAddresses,
            attachments: attachmentsArray,
            // command: "REPLY_TO_EMAIL",
          };
          const headers = {
            "Content-Type": "application/json",
          };
          const result = await axios.post(
            "https://kfofet10y2.execute-api.ap-northeast-2.amazonaws.com/DEV/updateTicket/replyToEmail",
            inputParams,
            {
              withCredentials: false,
              crossdomain: true,
            },
            headers
          );

          this.loading = false;
          if (result.data.status == "Success") {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: result.data.message,
            };
            setTimeout(() => {
              this.loading = false;
              this.dialogReplytToEmailEmit(2);
            }, 3000);
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: result.data.message,
            };
          }
          // const resultObj = JSON.parse(result.data["ReplyToEmail"]);
          // if (resultObj.status == "Success") {
          //   this.SnackBarComponent = {
          //     SnackbarVmodel: true,
          //     SnackbarColor: "green",
          //     Top: true,
          //     SnackbarText: resultObj.message,
          //   };
          //   setTimeout(() => {
          //     this.loading = false;
          //     this.dialogReplytToEmailEmit(2);
          //   }, 3000);
          // } else {
          //   this.loading = false;
          //   this.SnackBarComponent = {
          //     SnackbarVmodel: true,
          //     SnackbarColor: "red",
          //     Top: true,
          //     SnackbarText: resultObj.message,
          //   };
          // }
        } catch (error) {
          this.loading = false;
          console.log(error);
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Some thing went wrong, Try again later",
          };
        }
      }
    },

    dialogReplytToEmailEmit(Toggle) {
      this.$refs.form.reset();
      this.selectedTemplate = {
        email_template_content: "",
      };

      this.$emit("clicked", Toggle);
    },
  },
};
</script>
