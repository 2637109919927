<template>
  <div class="about">
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-container fluid class="container">
      <v-card tile elevation="0" class="card">
        <h1>Feedback Form</h1>
      </v-card>
      <v-card tile elevation="0" class="card2">
        <!-- <p class="text-center font-italic font-weight-thin">
          Here at Local it is our duty to serve you, the customer, and we take your feedback very seriously. Whether negative or positive, please let
          us know about your experience.
        </p> -->
        <v-form ref="form">
          <v-row class="mt-10">
            <!-- <v-col cols="12" md="6" class="mt-n2">
            <div class="font-weight-bold mb-3">Name</div>
            <v-text-field outlined> </v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="mt-n2">
            <div class="font-weight-bold mb-3">Email</div>
            <v-text-field outlined> </v-text-field>
          </v-col> -->
            <v-col cols="12" class="font-weight-bold">Please provide your feedback on the quality of our service</v-col>

            <v-col cols="12" md="9">
              <v-rating
                v-model="rating"
                :color="rating > 0 ? getRatingText.color : 'grey darken-1'"
                background-color="grey darken-1"
                empty-icon="$ratingFull"
                hover
                large></v-rating>
            </v-col>
            <v-col cols="12" md="3" class="">
              <h2 v-if="rating > 0" :class="`${getRatingText.color}--text`" class="mt-2">{{ getRatingText.text }}</h2>
            </v-col>
            <v-col cols="12" md="12" class="font-weight-bold">Do you have suggestions on what we can do to provide you with a better service?</v-col>
            <v-col cols="12" md="12" class="mt-n2">
              <v-textarea v-model="comments" placeholder="Type here..." outlined></v-textarea>
            </v-col>
            <v-col cols="12" justify="center" align="center">
              <v-btn
                :loading="loading"
                large
                class="px-8 white--text font-weight-bold"
                :disabled="comments === '' || rating < 1"
                color="green"
                @click="submitFeedBack"
                >Submit</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  components: { Snackbar },
  data: () => ({ innerHeight: 0, rating: 0, comments: "", ticketId: "", loading: false, SnackBarComponent: {} }),
  computed: {
    getRatingText() {
      const ratingObjects = [
        { text: "Poor", color: "red" },
        { text: "Average", color: "orange" },
        { text: "Good", color: "purple" },
        { text: "Very Good", color: "indigo" },
        { text: "Excellent", color: "green" },
      ];

      return ratingObjects[this.rating - 1];
    },
  },
  mounted() {
    this.innerHeight = window.innerHeight;
    const path = this.$route.path.split("/");
    this.ticketId = path[path.length - 1];
  },
  methods: {
    async submitFeedBack() {
      try {
        this.loading = true;
        let inputParams = {
          ticket_id: this.ticketId,
          comments: this.comments,
          feedback_values: {
            customer_happy: this.rating,
          },
        };
        const result = await axios.post(
          "https://kfofet10y2.execute-api.ap-northeast-2.amazonaws.com/DEV/updateTicket/customer-feedback",
          inputParams,
          {
            withCredentials: false,
            crossdomain: true,
          }
        );
        this.loading = false;

        if (result.status == 200 && result.data.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: result.data.message,
          };
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: result.data.message,
          };
        }
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Something went wrong, Please try later",
        };
      }
    },
  },
};
</script>

<style scoped>
.about {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 59px !important; /* Default padding */

  background-color: #ccc;
}
.container {
  width: 900px;
}
@media screen and (max-width: 1200px) {
  .about {
    padding: 40px 150px !important; /* Adjusted padding for medium-sized screens */
  }
}

@media screen and (max-width: 800px) {
  .about {
    padding: 30px 10px !important; /* Adjusted padding for small-sized screens */
  }
}

@media screen and (max-width: 600px) {
  .about {
    padding: 20px 5px !important; /* Adjusted padding for extra small-sized screens */
  }
}
.card {
  padding: 30px;
  background: #f2f1ee;
}
.card2 {
  padding: 30px;
  background: rgb(247, 240, 218);
}
</style>
