<template>
  <v-card tile>
    <v-card-text>
      <v-card v-if="isAttachmnetExist" elevation="0">
        <v-card width="100%" class="mt-4" elevation="0" v-for="(email, index) in emailAttachments" :key="index">
          <v-row no-gutters v-if="email.email_attachments && email.email_attachments.length > 0">
            <v-col cols="12" class="mt-1">
              <h4>
                {{ new Date(email.date).toLocaleString() }} from <span class="blue--text">{{ email.from ? email.from : email.fromAddress }}</span>
              </h4>
            </v-col>
            <v-col cols="12" class="mt-2">
              <v-row v-if="email.email_attachments && email.email_attachments.length > 0">
                <v-col cols="3" v-for="(attachment, id) in email.email_attachments" :key="id">
                  <v-card height="150px" width="150px" class="">
                    <template v-if="isImage(attachment.url)">
                      <img fullscreen :src="attachment.url" style="width: 100%; height: 80%" alt="Image" cover @click="toggleFullScreen" />
                    </template>
                    <template v-else-if="isVideo(attachment.url)">
                      <video :src="attachment.url" controls style="width: 100%; height: 80%"></video>
                    </template>
                    <template v-else>
                      <div class="file">
                        <v-icon large>{{ getIcon(attachment.url) }}</v-icon>
                      </div>
                    </template>
                    <div class="downloadButton"><v-icon class="" @click="open(attachment.url)">mdi-download</v-icon></div>
                    <div class="fontSizeSmall mt-1">{{ truncateString(attachment.filename) }}</div>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-divider :thickness="10" class="mt-2"></v-divider>
          </v-row>
        </v-card>
      </v-card>
      <v-card elevation="0" v-else class="text-center card">
        <div width="100%">
          <img @click="toggleFullScreen" src="../../assets/fileNotFound.jpg" alt="No Attachments Found" height="100px" width="100px" />
        </div>

        <h2 class="grey--text">No Attachments Found</h2>
      </v-card>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    emailAttachments: Array,
  },

  data: () => ({ isAttachmnetExist: false }),
  mounted() {
    this.isAttachmnetExist = this.checkAttachmnetExists(this.emailAttachments);
  },
  computed: {},
  methods: {
    truncateString(str, maxLength) {
      if (str.length > maxLength) {
        const extension = str.split(".");
        return str.substring(0, maxLength) + "..." + extension[extension.length - 1];
      }
      return str;
    },
    isImage(url) {
      if (
        url.endsWith("png") ||
        url.endsWith("PNG") ||
        url.endsWith("jpeg") ||
        url.endsWith("jpg") ||
        url.endsWith("gif") ||
        url.endsWith("giff") ||
        url.endsWith("bmp") ||
        url.endsWith("tif") ||
        url.endsWith("tiff") ||
        url.endsWith("ico")
      )
        return true;
      else false;
    },
    isVideo(url) {
      if (
        url.endsWith("mp4") ||
        url.endsWith("mpg") ||
        url.endsWith("mpeg") ||
        url.endsWith("m4v") ||
        url.endsWith("mov") ||
        url.endsWith("avi") ||
        url.endsWith("mkv") ||
        url.endsWith("wmv") ||
        url.endsWith("webm") ||
        url.endsWith("flv")
      )
        return true;
      else false;
    },
    toggleFullScreen(event) {
      console.log("jii", event);
      const img = event.target;
      if (img.requestFullscreen) {
        img.requestFullscreen();
      } else if (img.mozRequestFullScreen) {
        /* Firefox */
        img.mozRequestFullScreen();
      } else if (img.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        img.webkitRequestFullscreen();
      } else if (img.msRequestFullscreen) {
        /* IE/Edge */
        img.msRequestFullscreen();
      }
    },

    checkAttachmnetExists(emailAttachments) {
      let count = 0;
      for (let i = 0; i < emailAttachments.length; i++) {
        if (emailAttachments[i].email_attachments.length > 0) count++;
      }
      return count > 0 ? true : false;
    },
    open(url) {
      window.open(url);
    },
    getIcon(fileurl) {
      let url = fileurl.toLowerCase();
      if (
        url.endsWith("png") ||
        url.endsWith("PNG") ||
        url.endsWith("jpeg") ||
        url.endsWith("jpg") ||
        url.endsWith("gif") ||
        url.endsWith("giff") ||
        url.endsWith("bmp") ||
        url.endsWith("tif") ||
        url.endsWith("tiff") ||
        url.endsWith("ico")
      ) {
        return "mdi-file-image";
      } else if (url.endsWith("xlsx") || url.endsWith("xls") || url.endsWith("csv")) {
        return "mdi-file-excel-box";
      } else if (url.endsWith("docx") || url.endsWith("doc")) {
        return "mdi-file-word-box";
      } else if (url.endsWith("pdf")) {
        return "mdi-file-pdf-box";
      } else if (url.endsWith("zip")) {
        return "mdi-zip-box";
      } else {
        return "mdi-file-document";
      }
    },
  },
};
</script>
<style scoped>
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.file {
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
