<template>
  <div class="main-div">
    <Overlay :overlay="overlay" />
    <v-card class="background pa-2" :height="height" color="#fafafa" elevation="0">
      <!-- <div v-if="StoreObj.status && StoreObj.status != 'CLOSED' && StoreObj.status != ''"> -->
      <div>
        <CompleteStageWarning
          :action="action"
          :dialogcloseTicketWarning="dialogcloseTicketWarning"
          @clicked="dialogcloseTicketWarningEmit"
          :isExternal="true" />
        <AddCommentsToTicket :dialogaddComments="dialogaddComments" @clicked="dialogaddCommentsEmit" :StoreObj="StoreObj" />
        <Snackbar :SnackBarComponent="SnackBarComponent" />
        <v-app-bar color="#EEEDEB" bottom fixed class="hidden-md-and-up">
          <v-row align="center" justify="center">
            <v-btn class="text-capitalize" small color="secondary" @click="checkBrakepoit"> add comments </v-btn>
            <v-btn class="mx-2 text-capitalize" small color="secondary" @click="checkCompleteStage"> Close Ticket </v-btn>
          </v-row>
        </v-app-bar>
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="3" lg="3"
            ><v-card elevation="0" color="#f0f0f0" class="mb-2 py-0 rounded-lg overflow-y-auto" :height="OuterCardHeight">
              <v-card-text class="px-1 py-0"> <TicketInformation :isExternalStage="isExternalStage" :StoreObj="StoreObj" /> </v-card-text
            ></v-card>
          </v-col>

          <v-col cols="12" md="6" lg="6" v-if="!add_comments">
            <v-card elevation="0" color="#f0f0f0" class="mb-2 py-0 rounded-lg" :height="OuterCardHeight">
              <v-card-text class="px-1 py-0">
                <TimeLine :isExternalStage="isExternalStage" :StoreObj="StoreObj" @clicked="TimelineCardEmit" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-if="!add_comments" cols="12" md="3" lg="3">
            <v-card elevation="0" color="#f0f0f0" class="mb-2 rounded-lg" :height="OuterCardHeight">
              <v-card-text class="px-1 py-0">
                <TicketLogs :isExternalStage="isExternalStage" :StoreObj="StoreObj" />
              </v-card-text> </v-card
          ></v-col>
          <v-col cols="4" xs="12" sm="6" md="8" v-if="StoreObj.status != 'UNASSIGNED' && StoreObj.status !== 'AUTO_ASSIGNED' && add_comments">
            <v-row class="px-2">
              <v-col cols="12" md="12" class="mt-2" v-if="add_comments">
                <v-form ref="addCommentsForm">
                  <div class="font-weight-bold ml-3">Add Comments:</div>

                  <v-row>
                    <v-col cols="12" md="6" class="mt-2" v-if="add_comments">
                      <v-text-field
                        color="secondary"
                        v-model.trim="lowercaseEmail"
                        outlined
                        label="Enter Your Email Id *"
                        dense
                        :rules="[
                          (v) => !!v || 'Email ID is required',
                          (v) => /^[a-z0-9.]+@[a-z0-9]+(?:\.[a-z0-9]+)+$/.test(v) || 'Invalid email address',
                        ]">
                      </v-text-field
                    ></v-col>
                    <v-col cols="12" md="6" class="mt-2" v-if="add_comments"
                      ><v-file-input
                        color="secondary"
                        outlined
                        dense
                        multiple
                        v-model="selectedFiles"
                        label="Click to Upload Attachments"></v-file-input>
                    </v-col>
                    <v-col cols="12" md="12" class="mt-2" v-if="add_comments">
                      <v-textarea
                        color="secondary"
                        v-model="AddComments.comments"
                        outlined
                        label="Enter Comments *"
                        rows="3"
                        :rules="[(v) => !!v || 'Required']">
                      </v-textarea
                    ></v-col>
                  </v-row>
                </v-form>
              </v-col>

              <v-col cols="12" md="12" class="mt-2">
                <v-card-actions>
                  <v-spacer> </v-spacer>
                  <v-btn
                    v-if="StoreObj.status !== 'UNASSIGNED' && StoreObj.status !== 'AUTO_ASSIGNED' && add_comments"
                    small
                    outlined
                    color="redChipColor"
                    class="text-capitalize"
                    @click="(add_comments = false), (complete_stage = false)"
                    ><v-icon small left>mdi-close</v-icon>Close</v-btn
                  >
                  <v-btn
                    v-if="StoreObj.status !== 'UNASSIGNED' && StoreObj.status !== 'AUTO_ASSIGNED' && add_comments"
                    small
                    color="secondary mx-2"
                    class="text-capitalize ma-3"
                    @click="uploadFiles"
                    :loading="loading"
                    :disabled="complete_stage"
                    ><v-icon small left>mdi-check</v-icon> Submit
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="mt-3 hidden-sm-and-down" align="center" justify="center">
            <v-btn v-if="isExternalStage" class="text-capitalize" small color="secondary" @click="checkBrakepoit"> add comments </v-btn>
            <v-btn v-if="isExternalStage" class="mx-2 text-capitalize" small color="secondary" @click="checkCompleteStage"> Close Task </v-btn>
          </v-col>
        </v-row>
      </div>

      <div v-if="isError" class="error-message">
        <div><v-icon color="red" size="80">mdi-alert</v-icon></div>
        <p class="font-weight-bold">{{ error_message }}</p>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { GetColors } from "@/mixins/GetColors";

import Overlay from "@/components/Extras/Overlay.vue";
import Snackbar from "@/components/Extras/Snackbar.vue";
import TimeLine from "@/components/Tickets/TimeLine.vue";
import TicketLogs from "@/components/Tickets/TicketLogs.vue";

import TicketInformation from "@/components/Tickets/TicketInformation.vue";
import AddCommentsToTicket from "@/components/Extras/AddCommentsToTicket.vue";
import CompleteStageWarning from "@/components/Extras/CompleteStageWarning.vue";

export default {
  components: { Overlay, Snackbar, TimeLine, TicketLogs, TicketInformation, CompleteStageWarning, AddCommentsToTicket },
  mixins: [GetColors],
  data: () => ({
    ticketId: "",
    stageId: "",
    action: "",
    tempkey: "",
    error_message: "",

    StoreObj: {},
    SnackBarComponent: {},
    AddComments: {
      user_email_id: "",
      comments: "",
      attachments: [],
    },
    loading: false,
    overlay: false,
    isExternalStage: false,
    add_comments: false,
    complete_stage: false,
    dialogcloseTicketWarning: false,
    dialogaddComments: false,
    isError: false,

    selectedFiles: [],
    height: 0,
    OuterCardHeight: 0,
  }),

  computed: {
    lowercaseEmail: {
      get() {
        return this.AddComments.user_email_id.toLowerCase();
      },
      set(value) {
        this.AddComments.user_email_id = value;
      },
    },
  },
  watch: {
    add_comments(val) {
      if (val) {
        this.complete_stage = false;
      }
    },
    complete_stage(val) {
      if (val) {
        this.add_comments = false;
      }
    },
  },
  async mounted() {
    this.overlay = true;
    let urlArry = this.$route.fullPath.split("/");
    this.stageId = urlArry[urlArry.length - 1];
    this.ticketId = urlArry[urlArry.length - 2];
    await this.fetchTicketDetails();
    this.height = window.innerHeight - 20;
    this.OuterCardHeight = window.innerHeight - 120;
    this.overlay = false;
  },
  methods: {
    checkBrakepoit() {
      if (this.$vuetify.breakpoint.name == "xs" || this.$vuetify.breakpoint.name == "sm") this.dialogaddComments = true;
      else this.add_comments = true;
    },
    async fetchTicketDetails() {
      this.overlay = true;
      let InputParams = {
        ticket_id: this.ticketId,
      };
      try {
        let response = await axios.post("https://kfofet10y2.execute-api.ap-northeast-2.amazonaws.com/DEV/fetchTicket", InputParams, {
          withCredentials: false,
          crossdomain: true,
        });
        this.overlay = false;

        if (response.data.status == "Failed") {
          this.isError = true;
          this.error_message = response.data.message;
        } else {
          if (response.data.items) {
            if (response.data.items.ticket_stage_id === this.stageId) this.isExternalStage = true;
            this.StoreObj = response.data.items;

            this.StoreObj.customer_care_email_id = response.data.customer_care_email_id;
          }
        }
      } catch (error) {
        this.overlay = false;
        this.isError = true;

        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Something went wroing please try again aftersome time",
        };
      }
    },

    async addComments() {
      this.overlay = true;
      if (this.$refs.addCommentsForm.validate()) {
        if (this.AddComments.comments == "") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Fields marked with(*) are mandatory",
          };
          this.overlay = false;
          this.loading = false;
          return;
        }
        let inputParams = {
          user_email_id: this.AddComments.user_email_id.toLocaleLowerCase(),
          ticket_id: this.StoreObj.ticket_id,
          stage_name: this.StoreObj.ticket_stage_name,
          comments: this.AddComments.comments.replace(/\n/g, " "),
          attachments: this.AddComments.attachments,
        };
        try {
          let result = await axios.post("https://kfofet10y2.execute-api.ap-northeast-2.amazonaws.com/DEV/updateTicket", inputParams, {
            withCredentials: false,
            crossdomain: true,
          });
          this.overlay = false;
          if (result.data.status == "Success") {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: result.data.message,
            };
            location.reload(true);
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: result.data.message,
            };
          }
          this.loading = false;
        } catch (error) {
          this.loading = false;
          this.overlay = false;
          console.log("error in adding comment ", error);
        }
      } else {
        this.overlay = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with(*) are mandatory",
        };
        this.loading = false;
      }
    },

    async uploadFiles() {
      this.loading = true;
      this.AddComments.attachments = [];
      if (this.selectedFiles.length > 0) {
        for (let index in this.selectedFiles) {
          let file = this.selectedFiles[index];
          let preSignedUrl = await this.getPresignedUrl(file);
          try {
            const response = await axios.put(preSignedUrl, file, {
              headers: {
                "Content-Type": file.type,
              },
            });

            if (response.status == 200) {
              let file_url = `https://hellowrk-ticket-attachments.s3.ap-northeast-2.amazonaws.com/${this.tempkey}`;
              let fileDetails = {
                filename: file.name,
                url: file_url,
              };
              this.AddComments.attachments.push(fileDetails);
            } else {
              alert("Error uploading file");
            }
          } catch (error) {
            this.loading = false;
            alert("Error uploading file", error);
          }
        }
        this.addComments();
        this.selectedFiles = [];
      } else {
        this.addComments();
      }
    },

    async getPresignedUrl(file) {
      try {
        // const axios = require("axios");
        this.tempkey = `${this.StoreObj.organisation_id}/${this.StoreObj.ticket_id}/${
          this.StoreObj.ticket_stage_name
        }/${new Date().getTime()}/${file.name.replace(/ /g, "-")}`;
        const params = {
          key: this.tempkey,
          command: "WEB_FORM_S3_URL",
        };

        const config = {
          method: "post",
          url: "https://kfofet10y2.execute-api.ap-northeast-2.amazonaws.com/DEV/getPreSignedUrl",
          headers: {
            "Content-Type": "application/json",
          },
          data: params, // Include data as query parameters
        };

        const result = await axios.request(config);

        if (result.data.status == "Success") return result.data.message;
      } catch (error) {
        console.log("error in closing ticket", error);
      }
    },

    async closeTicket(email_id) {
      this.overlay = true;
      let inputParams = {
        ticket_id: this.StoreObj.ticket_id,
        workqueue_id: this.StoreObj.stage_assigned_to_workqueue_id,
        previous_stage_id: this.StoreObj.ticket_stage_id,
        previous_stage_name: this.StoreObj.ticket_stage_name,
        user_email_id: email_id,
      };
      try {
        let result = await axios.post("https://kfofet10y2.execute-api.ap-northeast-2.amazonaws.com/DEV/closeTicket", inputParams, {
          withCredentials: false,
          crossdomain: true,
        });

        this.overlay = false;
        if (result.data.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: result.data.message,
          };
          location.reload(true);
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: result.data.message,
          };
        }
      } catch (error) {
        this.overlay = false;
        console.log(error);
        alert("Error in closing ticket");
      }
    },
    checkCompleteStage() {
      this.complete_stage = true;
      this.action = "COMPLETE";
      this.dialogcloseTicketWarning = true;
    },
    dialogcloseTicketWarningEmit(Toggle, user_email_id) {
      this.complete_stage = false;
      this.dialogcloseTicketWarning = false;
      if (Toggle == 2) {
        this.closeTicket(user_email_id);
      }
    },
    dialogaddCommentsEmit(Toggle) {
      this.dialogaddComments = false;
      if (Toggle == 2) {
        location.reload(true);
      }
    },
    TimelineCardEmit(toggle) {
      if (toggle == 2) {
        this.fetchTicketDetails(this.StoreObj.ticketId);
      }
    },
  },
};
</script>
<style scoped>
.background {
  background: rgb(246, 230, 203);
  /* display: flex;
  align-items: center */
  /* justify-content: center; */
  /* padding: 0 10px; */
}
.error-message {
  /* Structure and Positioning */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;

  margin-bottom: 10px;
  max-width: 450px;
  height: 200px; /* Ensure readability for longer messages */
  border-radius: 10px; /* Softer edges */

  /* Background and Visual Appeal */
  /* background-color: #ffffff; Light gray for a neutral base */
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15); */
  /* Typography */
  font-family: Helvetica, Arial, sans-serif; /* Clean and modern font */
  color: #333; /* Darker text for contrast */
  text-align: center;

  /* Header Style */
}
h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
}

/* Paragraph Style */
p {
  font-size: 16px;
  line-height: 1.5;
}
/* .v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 0px;
} */
</style>
