export const GetColors = {
  methods: {
    getColor(status) {
      let item = {};
      switch (status) {
        case "failed":
          item.color = "redChipColor";
          item.text = "FAILED";
          break;
        case "completed":
          item.color = "greenChipColor";
          item.text = "COMPLETED";
          break;
        case "no-answer":
          item.color = "primary";
          item.text = "NO-ANSWER";
          break;
        case "busy":
          item.color = "#34d5eb";
          item.text = "BUSY";
          break;
        case "UNASSIGNED":
          item.color = "redChipColor";
          item.text = "UNASSIGNED";
          break;
        case "CLOSED":
          item.color = "greenChipColor";
          item.text = "CLOSED";
          break;
        case "IN PROGRESS":
          item.color = "primary";
          item.text = "IN PROGRESS";
          break;
        case "ARCHIVED":
          item.color = "red";
          item.text = "REJECTED";
          break;
        case "AUTO-ASSIGNED":
          (item.color = "cyan"), (item.text = "A. ASSIGNED");
      }
      return item;
    },
    getIcon(fileName) {
      let name = fileName.toLowerCase();
      if (
        name.endsWith("png") ||
        name.endsWith("PNG") ||
        name.endsWith("jpeg") ||
        name.endsWith("jpg") ||
        name.endsWith("gif") ||
        name.endsWith("giff") ||
        name.endsWith("bmp") ||
        name.endsWith("tif") ||
        name.endsWith("tiff") ||
        name.endsWith("ico")
      ) {
        return "mdi-file-image";
      } else if (name.endsWith("xlsx") || name.endsWith("xls") || name.endsWith("csv")) {
        return "mdi-file-excel-box";
      } else if (name.endsWith("docx") || name.endsWith("doc")) {
        return "mdi-file-word-box";
      } else if (name.endsWith("pdf")) {
        return "mdi-file-pdf-box";
      } else if (name.endsWith("zip")) {
        return "mdi-zip-box";
      } else {
        return "mdi-file-document";
      }
    },
    truncatedFileName(fileName) {
      const maxLength = 3;
      if (fileName.length <= maxLength) {
        return fileName.toLowerCase();
      } else {
        let name = fileName.toLowerCase();
        const truncatedPart = name.substr(0, 1);
        const extension = name.split(".").pop();
        return `${truncatedPart}...${extension}`;
      }
    },
  },
};
