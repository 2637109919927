import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#f47f40", // #E53935
        secondary: "#343f52", // #FFCDD2

        redChipColor: "#f58488",
        greenChipColor: "#99f0b3",
      },
    },
  },
});
