<template>
  <v-snackbar
    v-model="SnackBarComponent.SnackbarVmodel"
    elevation="24"
    :color="SnackBarComponent.SnackbarColor"
    top
    :timeout="SnackBarComponent.timeout">
    <div class="text-center">{{ SnackBarComponent.SnackbarText }}</div>
  </v-snackbar>
</template>
<script>
export default {
  props: {
    SnackBarComponent: Object,
  },
};
</script>
