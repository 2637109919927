<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <Overlay :overlay="overlay" />
    <v-dialog v-model="dialogaddComments" persistent max-width="400px">
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="primary">
          <div>Add Comments</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogaddCommentsEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="px-2" no-gutters>
            <v-col cols="12" md="12" class="mt-2">
              <v-form ref="addCommentsForm">
                <v-row no-gutters>
                  <v-col cols="12" md="6" class="">
                    <v-text-field
                      color="secondary"
                      v-model.trim="lowercaseEmail"
                      outlined
                      label="Enter Your Email Id *"
                      dense
                      :rules="[
                        (v) => !!v || 'Email ID is required',
                        (v) => /^[a-z0-9.]+@[a-z0-9]+(?:\.[a-z0-9]+)+$/.test(v) || 'Invalid email address',
                      ]"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class=""
                    ><v-file-input
                      color="secondary"
                      outlined
                      dense
                      multiple
                      v-model="selectedFiles"
                      prepend-inner-icon="mdi-upload"
                      prepend-icon=""
                      label="Click to Upload Attachments"></v-file-input>
                  </v-col>
                  <v-col cols="12" md="12" class="">
                    <v-textarea
                      color="secondary"
                      v-model.trim="AddComments.comments"
                      outlined
                      label="Enter Comments *"
                      rows="3"
                      :rules="[(v) => !!v || 'Required']">
                    </v-textarea
                  ></v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="py-2">
          <v-row no-gutters align="center" justify="center">
            <v-btn color="secondary text-capitalize" small class="mx-2" @click="dialogaddCommentsEmit(1)">cancel</v-btn>
            <v-btn color="primary text-capitalize" small :loading="loading" @click="uploadFiles" :disabled="disabled">Submit</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import Snackbar from "@/components/Extras/Snackbar.vue";
import Overlay from "@/components/Extras/Overlay.vue";
export default {
  props: {
    StoreObj: Object,
    dialogaddComments: Boolean,
  },
  components: { Snackbar, Overlay },
  data: () => ({
    action: "",
    tempkey: "",

    SnackBarComponent: {},
    AddComments: {
      user_email_id: "",
      comments: "",
      attachments: [],
    },

    overlay: false,

    selectedFiles: [],
    height: 0,
    loading: false,
    disabled: true,
  }),
  computed: {
    lowercaseEmail: {
      get() {
        if (this.AddComments.user_email_id) return this.AddComments.user_email_id.toLowerCase();
      },
      set(value) {
        if (value) this.AddComments.user_email_id = value;
      },
    },
  },
  watch: {
    "AddComments.user_email_id"() {
      this.isFormFilled();
    },
    "AddComments.comments"() {
      this.isFormFilled();
    },
  },
  methods: {
    isFormFilled() {
      if (this.$refs.addCommentsForm.validate() && this.AddComments.comments != "") this.disabled = false;
    },
    async getPresignedUrl(file) {
      try {
        this.tempkey = `${this.StoreObj.organisation_id}/${this.StoreObj.ticket_id}/${
          this.StoreObj.ticket_stage_name
        }/${new Date().getTime()}/${file.name.replace(/ /g, "-")}`;
        const params = {
          key: this.tempkey,
        };

        const config = {
          method: "post",
          url: "https://kfofet10y2.execute-api.ap-northeast-2.amazonaws.com/DEV/getPreSignedUrl",
          headers: {
            "Content-Type": "application/json",
          },
          data: params,
        };

        const result = await axios.request(config);

        if (result.data.status == "Success") return result.data.message;
      } catch (error) {
        console.log("error in closing ticket", error);
      }
    },
    async uploadFiles() {
      this.loading = true;
      this.AddComments.attachments = [];
      if (this.selectedFiles.length > 0) {
        let file = this.selectedFiles[0];

        let preSignedUrl = await this.getPresignedUrl(file);
        try {
          const response = await axios.put(preSignedUrl, file, {
            headers: {
              "Content-Type": file.type,
            },
          });

          if (response.status == 200) {
            let file_url = `https://hellowrk-ticket-attachments.s3.ap-northeast-2.amazonaws.com/${this.tempkey}`;
            let fileDetails = {
              filename: file.name,
              url: file_url,
            };
            this.AddComments.attachments.push(fileDetails);
            this.addComments();
          } else {
            alert("Error uploading file");
          }
        } catch (error) {
          this.loading = false;
          console.error("Error uploading file:", error);
          alert("Error uploading file");
        }
      } else {
        this.addComments();
      }
    },
    async addComments() {
      this.loading = true;
      if (this.$refs.addCommentsForm.validate()) {
        let inputParams = {
          user_email_id: this.AddComments.user_email_id,
          ticket_id: this.StoreObj.ticket_id,
          stage_name: this.StoreObj.ticket_stage_name,
          comments: this.AddComments.comments.replace(/\n/g, " "),

          attachments: this.AddComments.attachments,
        };
        try {
          let result = await axios.post("https://kfofet10y2.execute-api.ap-northeast-2.amazonaws.com/DEV/updateTicket", inputParams, {
            withCredentials: false,
            crossdomain: true,
          });
          this.overlay = false;
          if (result.data.status == "Success") {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: result.data.message,
            };
            setTimeout(() => {
              this.loading = false;
              this.dialogaddCommentsEmit(2);
            }, 3500);
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: result.data.message,
            };
          }
        } catch (error) {
          this.loading = false;
          console.log("error in adding comment ", error);
        }
      } else {
        // ?????
      }
    },
    dialogaddCommentsEmit(Toggle) {
      this.$refs.addCommentsForm.reset();

      this.$emit("clicked", Toggle);
    },
  },
};
</script>
